import request from '@/config/request.js'
import setting from '@/config/settings'
import common from '@/components/common/'
const ApiEvaluation = request.File

import * as qiniuYun from 'qiniu-js'

export default {
  components: common,
  name: 'upload-list',
  data () {
    return {
      appUrl: setting.gbs.host,
      search_data: {
        filters: [{
          field: 'fileName',
          operator: 'like',
          value: ''
        }, {
          field: 'isEnable',
          operator: 'eq',
          value: ''
        }, {
          field: 'fileDirectory',
          operator: 'eq',
          value: ''
        }, {
          field: 'del',
          operator: 'eq',
          value: false
        }],
        current_page: 1,
        total: 0,
        page_size: 10,
      },
      tableList: [],
      status: [
        {
          name: '已使用',
          value: true
        },
        {
          name: '未使用',
          value: false
        }
      ],
      myHeaders: { Authorization: this.$store.state.login.token },
      formLabelWidth: '120px',
      uploadDailog: false,
      uploadDetails: {
        paperName: '',
        categoryPath: '',
        paperUrl: '',
        categoryPath2: '',
        size: '',
        suffix: ''
      },
      rules: {
        uploadUrl: [
          { required: true, message: '请上传文件', trigger: 'change' }
        ]
      },
      fileList: [],
      treeData: [
        {
          directoryId: '-1',
          disabled: true,
          directoryName: '全部资料',
          children: []
        }
      ],
      defaultShowNodes: ['-1'],
      defaultProps: {
        children: 'children',
        label: 'directoryName'
      },
      checkNodes: [],
      treeDailog: false,
      treeEditDailog: false,
      treeEditFrom: {
        directoryId: '',
        directoryName: ''
      },
      treeNodeFrom: {
        parentId: '',
        directoryName: ''
      },
      rows: [],
      uploadList: [],
      btnLoading: false,
      totalFiles: 0,
      successfulUploads: 0,
      failedUploads: 0,
      uploading: [],
      onEditFile: {},
      editVisible: false,
      fileRules: {
        fileName: [
          { required: true, message: '请填写文件名称', trigger: 'blur' }
        ]
      },
      fileMoveDailog: false,
      fileMoveTreeFrom: {},
      uploadInfo: {
        token: "test"
      },
      fileIndex: 0,
      newUploadFile: [],
      percentage: 0,
      percentageFileName: '',
      uploadBegin: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 330
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? '满' : `${percentage}%`;
    },
    onShowFilemove () {
      if (this.rows.length < 1) {
        this.$message({
          type: 'warning',
          message: '请勾选需要移动的文件!'
        })
        return
      }
      this.fileMoveDailog = true
    },
    onEditVisible (data) {
      this.onEditFile = Object.assign({}, data)
      this.editVisible = true
    },
    initData () {
      var data = {
        offset: (this.search_data.current_page - 1) * this.search_data.page_size,
        limit: this.search_data.page_size,
        data: {},
        filters: null,
        orFilters: null,
        order: 'uploadTime',
        sort: 'desc'
      }
      data = Object.assign(data, ({
        filters: this.search_data.filters.filter(item => {
          if (item.value !== '') {
            return item
          }
        })
      } || {}))
      ApiEvaluation.pageFileDirectoryDetails.call(this, data, (listData) => {
        this.tableList = listData.content
        this.search_data.total = parseInt(listData.totalElements)
      })
    },
    handleSelectionChange (val) {
      this.rows = val
    },
    onDeleteFiles () {
      if (this.rows.length > 0) {
        this.$confirm('确定删除选中的文件？ 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let arr = []
          this.rows.map(item => {
            arr = [item.fileId, ...arr]
          })
          ApiEvaluation.batchUpdateFileDel.call(this, {
            del: true, fileids: arr
          }, res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.onSearch()
          })
        })
      } else {
        this.$message({
          type: 'error',
          message: '请勾选需要删除的文件!'
        })
      }
    },
    onDeleteFile (row) {
      this.$confirm('确定删除该文件？ 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const arr = [row.fileId]
        ApiEvaluation.batchUpdateFileDel.call(this, {
          del: true, fileids: arr
        }, res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.onSearch()
        })
      })
    },
    deleteFileDirectory (data) {
      this.$confirm('确定删除选中的文件目录？ 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.deleteFileDirectory.call(this, { directoryId: data.directoryId }, res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getTree()
          // this.initData()
        })
      })
    },
    getNode (data) {
      this.checkNodes = data
      this.search_data.filters[2].value = data.directoryId ? data.directoryId : ''
      this.initData()
    },
    append (data) {
      this.treeNodeFrom.parentId = data.directoryId ? data.directoryId : ''
      this.treeEditFrom.directoryId = data.directoryId
      this.treeDailog = true
    },
    onEdit (data) {
      this.treeEditFrom.directoryId = data.directoryId
      this.treeEditFrom.directoryName = data.directoryName
      this.treeEditDailog = true
    },
    onCancelFileMove (str) {
      this.$refs.tree.setCheckedKeys([]);
      this.$data[str] = false
    },
    onSaveMoveFile (str) {
      const directoryId = this.$refs.tree.getCheckedKeys()
      if (directoryId.length < 1) {
        this.$message({
          type: 'warning',
          message: '请选择要移动的目录!'
        })
      } else if (directoryId.length > 1) {
        this.$message({
          type: 'warning',
          message: '请选择单个目录!'
        })
      } else {
        const fileIds = []
        this.rows.map(item => {
          fileIds.push(item.fileId)
        })
        ApiEvaluation.onMoveFilesDirectory.call(this, { fileIds: fileIds, directoryId: directoryId[0] }, () => {
          this.initData()
          this.$refs.tree.setCheckedKeys([]);
          this.$data[str] = false
        })
      }

    },
    onCancelTree (str, formName) {
      this.$refs[formName].resetFields()
      this.$nextTick(() => {
        this.$data[str] = false
        this.onEditFile = {}
      })
    },
    onCancel (formName) {
      this.$refs[formName].resetFields()
      this.btnLoading = false
      this.fileList = []
      this.uploading = []
      this.uploadList = []
      this.uploadDetails = {
        paperName: '',
        categoryPath: '',
        paperUrl: '',
        categoryPath2: '',
        size: '',
        suffix: ''
      }
      if (this.uploadBegin.length > 0) {
        this.uploadBegin.map(item => {
          item.unsubscribe()
        })
      }
      this.percentage = 0

    },
    setEditShow (row) {
      ApiEvaluation.getExamPaper.call(this, {
        paperId: row.paperId
      }, (res) => {
        const path = (res.categoryPath.split(',')).map(i => {
          return parseInt(i)
        })
        this.uploadDetails = {
          paperName: res.paperName,
          categoryPath: path[0],
          categoryPath2: path[1],
          paperUrl: res.paperUrl,
          paperId: res.paperId
        }
        this.$nextTick(() => {
          ApiEvaluation.getTopCategory.call(this, {}, (listData) => {
            this.categoryTable = listData
            ApiEvaluation.getCategoryByTypeId.call(this, { typeId: path[0] }, (listData) => {
              this.papersDailog = true
            })
          })
        })
      })
    },
    handleChange (file, fileList) {
    },
    /*handleAvatarError(err, res, file) {
      // 更新 fileList，标记上传失败的文件
      const errorFile = {
        ...file,
        status: 'error', // 设置文件状态为错误
      };
      this.fileList.push(errorFile);

      // 检查是否所有文件都已上传完成
      if (this.isAllFilesUploaded()) {
        console.log('All files uploaded (with errors)');
        // 执行其他操作，如刷新表单
        this.initData();
      }
    },*/
    /*isAllFilesUploaded() {
      // 检查是否所有文件都已上传完成
      return this.fileList.filter(file => file.status !== 'uploading').length === this.fileList.length;
    },*/
    isAllFilesUploaded() {
      return this.fileList.every(file => file.status === 'success');
    },
    handleAvatarError (err, res, file) {
      this.btnLoading = false
      console.log(err, res, file)
    },
    getfilesize(size) {
      if (!size)
        return "";
      var num = 1024.00; //byte
      if (size < num)
        return size + "B";
      if (size < Math.pow(num, 2))
        return (size / num).toFixed(2) + "K"; //kb
      if (size < Math.pow(num, 3))
        return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
      if (size < Math.pow(num, 4))
        return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
      return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
    },
    qiniuBeginUpload (list) {
      let that = this
      for (let i = 0; i < list.length; i++) {
        const config = {
          useCdnDomain: true,
          region: qiniuYun.region.z2
        };
        const keyName = 'bellFiles/'+ `${new Date().getTime()}${Math.round(Math.random()*10)}` + '/' +  list[i].file.name
        const putExtra = {
          fname: keyName
        }
        const observer = {
          next(res){
            that.percentage = parseInt(res.total.percent)
            that.percentageFileName = list[i].file.name
            console.log(that.percentage, res.total.percent)
            // ...
          },
          error(err){
            // ...
          },
          complete(res){
            let format =  keyName.substring(keyName.lastIndexOf(".") + 1)
            that.fileIndex = that.fileIndex + 1;
            that.newUploadFile.push({
              fileName: list[i].file.name,
              fileSize: that.getfilesize(list[i].file.size),
              fileUrl: keyName,
              fileFormat: format.toUpperCase()
            })
            if (that.fileIndex === that.fileLength.length) {
              ApiEvaluation.saveFileDirectoryDetails.call(that, { fileDirectoryId: that.checkNodes.directoryId, fileList: that.newUploadFile }, (listData) => {
                that.$message({
                  type: 'success',
                  message: '上传成功!'
                })
                that.initData()
                that.btnLoading = false
                that.uploadDailog = false
                that.uploading = []
                that.uploadList = []
                that.newUploadFile = []
                that.fileIndex = 0
                that.percentage = 0
              }, (err => {
                that.btnLoading = false
                that.uploadDailog = false
                that.uploading = []
                that.uploadList = []
                that.newUploadFile = []
                that.fileIndex = 0
                that.percentage = 0
              }))
            }

          }
        }
        let file = list[i].file
        const observable = qiniuYun.upload(
          file,
          keyName,
          this.uploadInfo.token,
          putExtra,
          config)
        this.uploadBegin.push(observable.subscribe(observer)) // 上传开始
      }
    },
    onSuccess () {
    },
    async qiniuUploadCover (list) {
      console.log(list, '---------------')
      this.uploadList = [...this.uploadList, list]
      if (this.uploadList.length === this.fileLength.length) {
        await this.qiniuBeginUpload(this.uploadList)

      }

      /*const config = {
        useCdnDomain: true,
        region: qiniuYun.region.z2
      };
      const keyName = 'bellFiles/'+ `${new Date().getTime()}${Math.round(Math.random()*10)}` + '/' +  list.file.name
      const putExtra = {
        fname: keyName
      }
      const observer = {
        next(res){
          console.log(res, 1)
          // ...
        },
        error(err){
          console.log(err, 2)
          // ...
        },
        complete(res){
          console.log(res, 'res3')
          console.log(this.uploadList)
          if (this.uploadList.length === this.fileLength.length) {
            ApiEvaluation.saveFileDirectoryDetails.call(this, { fileDirectoryId: this.checkNodes.directoryId, fileList: this.uploadList }, (listData) => {
              this.$message({
                type: 'success',
                message: '上传成功!'
              })
              this.initData()
              this.btnLoading = false
              this.uploadDailog = false
              this.uploading = []
              this.uploadList = []
            }, (err => {
              this.btnLoading = false
              this.uploadDailog = false
              this.uploading = []
              this.uploadList = []
            }))
          }
            // this.index = this.index + 1;
          // const  newData = [...todayStore.imageArr] // pointer problem
          // newData.push(res.key)
          // todayStore.imageArr = newData
        }
      }
      let file = list.file
      const observable = qiniuYun.upload(
        file,
        keyName,
        this.uploadInfo.token,
        putExtra,
        config)
      observable.subscribe(observer) // 上传开始*/
    },
    handleAvatarSuccess (res, file) {
      this.uploadList = [res, ...this.uploadList]
      if (this.uploadList.length === this.uploading.length) {
        let arr = []
        this.uploadList.filter(item => {
          if (!item.uploadErrorMessage) {
            arr = [item[0], ...arr]
          }
        })
        /*ApiEvaluation.saveFileDirectoryDetails.call(this, { fileDirectoryId: this.checkNodes.directoryId, fileList: arr }, (listData) => {
          this.$message({
            type: 'success',
            message: '上传成功!'
          })
          this.initData()
          this.btnLoading = false
          this.uploadDailog = false
          this.uploading = []
          this.uploadList = []
        }, (err => {
          this.btnLoading = false
          this.uploadDailog = false
          this.uploading = []
          this.uploadList = []
        }))*/


      }
    },
    async handleAvatarSuccess1(res, file) {
      if (!res[0].uploadErrorMessage) {
       await ApiEvaluation.saveFileDirectoryDetails.call(this, { fileDirectoryId: this.checkNodes.directoryId, fileList: res }, (listData) => {
          // 更新 fileList，标记上传成功的文件
          const successFile = {
            ...file,
            response: res, // 将上传成功的响应信息保存在 response 属性中
            status: 'success', // 设置文件状态为成功
          };
          this.fileList.push(successFile);
          // 检查是否所有文件都已上传完成
          if (this.isAllFilesUploaded()) {
            // 执行其他操作，如刷新表单
            this.uploadDailog = false
            this.fileList = []
            this.initData();
          }
        });
      } else {
        // 更新 fileList，标记上传失败的文件
        const errorFile = {
          ...file,
          status: 'error', // 设置文件状态为错误
        };
        this.fileList.push(errorFile);
        // 检查是否所有文件都已上传完成
        if (this.isAllFilesUploaded()) {
          // 执行其他操作，如刷新表单
          this.uploadDailog = false
          this.fileList = []
          this.initData();
        }
      }
    },
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < (1 * 1024);
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 1G!');
      }
      if (isLt20M) {
        this.uploading.push(file)
      }
      return isLt20M
    },
    handleRemove (file, fileList) {

    },
    async onSubmit(formName) {
      const that = this
      that.btnLoading = true
      await that.onUploadSubmit();
    },
    async onUploadSubmit() {
      this.fileLength = this.$refs.upload.$children[0].fileList
      // console.log(this.fileList, this.$refs.upload, )
      await this.$refs.upload.submit();
      // console.log(this.fileList, this.$refs.upload.$children[0].fileList, 'end')
      // console.log('1111111111111111', this.uploadList)
    },
    saveData() {
      // 调用保存接口的逻辑
      // ...
    },
    onSubmitFileEditName (str, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiEvaluation.onUpdataFileDetails.call(this, this.onEditFile, () => {
            this.$refs[formName].resetFields()
            this.$data[str] = false
            this.onEditFile = {}
            this.initData()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onSubmitTreeNodeName (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiEvaluation.addFileDirectory.call(this, this.treeNodeFrom, () => {
            this.$refs[formName].resetFields()
            this.getTree()
            this.treeDailog = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onSubmitTreeEditName (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiEvaluation.editFileDirectory.call(this, this.treeEditFrom, () => {
            this.$refs[formName].resetFields()
            this.getTree()
            this.treeEditDailog = false
            // this.treeEditFrom.directoryId
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    oldonSubmit () {
      this.$refs.upload.submit()
      /*this.onUploadSubmit().then(() => {
        console.log(1, this.fileList.length);
        // 在这里可以继续处理其他逻辑
      })*/

    },

    onShowUploadDailog () {
      if (this.checkNodes.length === 0 || !this.checkNodes.directoryId) {
        this.$message({
          type: 'error',
          message: '请先选择根目录以下的子目录!'
        })
      } else {
        this.uploadDailog = true
      }
    },
    setStatus (row) {
      this.$confirm('确定' + (row.isEnable ? '关闭' : '开启') + '该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.updateExamPaperStatus.call(this, { fileId: row.fileId, isEnable: (row.isEnable ? false : true) }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.initData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    delPaper (id) {
      this.$confirm('确定删除该记录？ 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.recyclePaper.call(this, { paperId: id }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.initData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    setNew (id) {
      this.$confirm('确定把该条记录设置为最新, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.stickPaper.call(this, { paperId: id }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.initData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    resetTable () {
      this.search_data.filters[0].value = ''
      this.search_data.filters[1].value = ''
      this.initData()
    },
    /**
     * 搜索事件
     */
    onSearch () {
      this.search_data.current_page = 1
      this.initData()
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.search_data.current_page = page
      this.initData()
    },
    getTree () {
      let that = this
      ApiEvaluation.getTree.call(this, {}, (listData) => {
        this.treeData[0].children = listData
        that.$nextTick(() => {
          this.defaultShowNodes = [this.treeEditFrom.directoryId]
        })
      })
    },
    getQiniuToken () {
      ApiEvaluation.getQiniuToken.call(this,{},(response => {
        // accessKey: K_PoU5GHwNnkXNnKWujsO-IBNKBLDItKGsl3U9Oq
        // secretKey: wTmuRK_IOmFQcv8elEkF4U5Nx5vySKpBGjGdtH5V
        // bucket: anqinyun
        // domain: https://qiniu.anqinyun.com/
        // this.uploadInfo.bucketHost = response.bucketHost;
        // this.uploadInfo.supportWebp = false;
        this.uploadInfo.token = response
      }));
    },
  },
  mounted () {
    this.getTree()
    this.initData()
    this.getQiniuToken()
  }
}
