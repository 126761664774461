export default {
  name: 'left-menu',
  data () {
    return {
      menuList: [{
        text: '测评中心',
        icon: 'bell-icon-yucepingfen',
        nodes: [{
          menuPath: '/evaluation/create-teacher-papers',
          text: '创建老师试卷'
        }, {
          menuPath: '/evaluation/analysis',
          text: '测评数据分析'
        }, {
          menuPath: '/evaluation/del-analysis',
          text: '资料回收站'
        }]
      },
      {
        text: '文件管理',
        icon: 'bell-icon-wenjianguanli1',
        nodes: [{
          menuPath: '/file/upload-list',
          text: '资料上传'
        }, {
          menuPath: '/file/del-file',
          text: '资料回收站'
        }]
      }]
    }
  },

  mounted () {
  },
  watch: {
    $route () {
      this.addTags()
    }
  },
  computed: {
    route () {
      return this.$route
    },
    isCollapse () {
      return this.$store.state.header.isCollapse
    }
  },
  methods: {
    handleOpen (key, keyPath) {
    },
    handleClose (key, keyPath) {
    },
    onReplace (str) {
      let path = str.split('.html').join('')
      if (path[0] !== '/') {
        path = '/' + path
      }
      return path
    },
    addTags () {
      const route = this.$route// 获取地址栏路由
      if (route.path !== '/home/index') {
        this.$store.dispatch('setRouteList', route)
        this.$store.dispatch('addCachedView', route)
      }
    }
  }
}
