import ajax from './ajax'
export default {
  // 用户管理-登陆、用户设置
  UserLogin: {
    login (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/auth/login', data, success, false, errFn)
    }
  },
  Home: {
    // 在校员工情况
    getZoneTeacherByPosition (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/teacher/getZoneTeacherByPosition', data, success, false, errFn)
    },
    // 在校员工情况
    getZoneStudentByGrade (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/student/getZoneStudentByGrade', data, success, false, errFn)
    }
  },
  Evaluation: {
    getPaperInfo (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/getPaperInfo', data, success, false, errFn)
    },
    getTopCategory (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/getTopCategory', data, success, false, errFn)
    },
    getCategoryMap (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/getCategoryMap', data, success, false, errFn)
    },
    addExamPaper (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/addExamPaper', data, success, false, errFn)
    },
    stickPaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/stickPaper', data, success, false, errFn)
    },
    updateExamPaperStatus (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/updateExamPaperStatus', data, success, false, errFn)
    },
    getExamStatics (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/getExamStatics', data, success, false, errFn)
    },
    getExamPaperRecycles (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/getExamPaperRecycles', data, success, false, errFn)
    },
    recyclePaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/recyclePaper', data, success, false, errFn)
    },
    updateExamPaperLifeByIds (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/updateExamPaperLifeByIds', data, success, false, errFn)
    },
    getExamPaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/getExamPaper', data, success, false, errFn)
    },
    updateExamPaper (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/updateExamPaper', data, success, false, errFn)
    },
    getSchoolExamStatics (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/getSchoolExamStatics', data, success, false, errFn)
    },
    getSchoolPerformancies (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/getSchoolPerformancies', data, success, false, errFn)
    }
  },
  File: {
    pageFileDirectoryDetails (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/fileManagerNew/pageFileDirectoryDetails', data, success, false, errFn)
    },
    getTopCategory (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/file/getTopCategory', data, success, false, errFn)
    },
    getCategoryByTypeId (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/file/getCategoryByTypeId', data, success, false, errFn)
    },
    getCategoryMap (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/file/getCategoryMap', data, success, false, errFn)
    },
    addExamPaper (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/file/addExamPaper', data, success, false, errFn)
    },
    stickPaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/file/stickPaper', data, success, false, errFn)
    },
    updateExamPaperStatus (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/fileManagerNew/updateFileIsEnable', data, success, false, errFn)
    },
    getExamStatics (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/file/getExamStatics', data, success, false, errFn)
    },
    getExamPaperRecycles (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/file/getExamPaperRecycles', data, success, false, errFn)
    },
    recyclePaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/file/recyclePaper', data, success, false, errFn)
    },
    updateExamPaperLifeByIds (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/file/updateExamPaperLifeByIds', data, success, false, errFn)
    },
    getExamPaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/file/getExamPaper', data, success, false, errFn)
    },
    updateExamPaper (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/file/updateExamPaper', data, success, false, errFn)
    },
    getSchoolExamStatics (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/file/getSchoolExamStatics', data, success, false, errFn)
    },
    getSchoolPerformancies (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/file/getSchoolPerformancies', data, success, false, errFn)
    },
    // getPaperInfo (data, success, errFn) {
    //   ajax.call(this, 'post', 'web/rest/file/getPaperInfo', data, success, false, errFn)
    // },
    getTree (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/fileManagerNew/queryFileDirectoryTree', data, success, false, errFn)
    },
    addFileDirectory (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/fileManagerNew/addFileDirectory', data, success, false, errFn)
    },
    editFileDirectory (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/fileManagerNew/updateFileDirectory', data, success, false, errFn)
    },
    updateFileDel (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/fileManagerNew/updateFileDel', data, success, false, errFn)
    },
    deleteFileDirectory (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/fileManagerNew/deleteFileDirectory', data, success, false, errFn)
    },
    deleteDirectoryFile (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/fileManagerNew/deleteDirectoryFile', data, success, false, errFn)
    },
    saveFileDirectoryDetails (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/fileManagerNew/saveFileDirectoryDetails?fileDirectoryId=' + data.fileDirectoryId, data.fileList, success, false, errFn)
    },
    batchUpdateFileDel (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/fileManagerNew/batchUpdateFileDel?del=' + data.del, data.fileids, success, false, errFn)
    },
    onUpdataFileDetails (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/fileManagerNew/updateFileDirectoryDetails', data, success, false, errFn)
    },
    onMoveFilesDirectory (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/fileManagerNew/moveFilesDirectory', data, success, false, errFn)
    },
    getQiniuToken (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/fileManagerNew/getQiniuToken', data, success, false, errFn)
    },
  }
}
